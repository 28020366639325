<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="管理员">
              <a-input
                placeholder="请输入管理员账号"
                v-model="search.userName"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="操作日期">
              <a-range-picker @change="onTimeChange" />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item>
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <a-table
        rowKey="id"
        size="small"
        bordered
        :columns="list.columns"
        @change="onPageChange"
        :pagination="list.pagination"
        :dataSource="list.datas"
        :loading="isloading"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: list.tableHeight }"
      >
        <span slot="status" slot-scope="status">
          <a-tag v-if="status" color="green">成功</a-tag>
          <a-tag v-else color="red">失败</a-tag>
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    dataIndex: "title",
    key: "title",
    align: "center",
    title: "操作名称",
    width: 300,
  },
  {
    dataIndex: "content",
    key: "content",
    align: "center",
    title: "操作内容",
    ellipsis: true,
  },
  {
    dataIndex: "ip",
    key: "ip",
    align: "center",
    title: "IP地址",
    width: 160,
  },
  {
    dataIndex: "time",
    key: "time",
    align: "center",
    title: "操作时间",
    width: 160,
  },
  {
    dataIndex: "ok",
    key: "ok",
    align: "center",
    title: "操作结果",
    width: 160,
    scopedSlots: { customRender: "status" },
  },
];

let _this;
export default {
  name: "logrecord",
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 252,
      },
      isloading: false,
      search: {
        startTime: "",
        endTime: "",
        userName: "",
      },
    };
  },
  mounted: function () {
    _this = this;
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 252;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.GetList();
  },
  methods: {
    GetList() {
      _this.isloading = true;
      _this.$api.systemlog
        .getPagedList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.search.userName,
          _this.search.startTime,
          _this.search.endTime
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onTimeChange(date, dateString) {
      _this.search.startTime = dateString[0];
      _this.search.endTime = dateString[1];
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.cang_sta {
  text-align: center;
}

.cang_sta img {
  width: 35px;
}
</style>

